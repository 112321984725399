interface Translations {
    "view-order.list": string;
    "view-order.search.pro": string;
    "view-order.search.pro.section1": string;
    "view-order.search.pro.section2": string;
    "view-order.search.pro.section3": string;
    "view-order.search.pro.data.inizio": string;
    "view-order.search.pro.data.fine": string;
    "view-order.search.pro.vista": string;
    "view-order.search.pro.ordinamento": string;
    "view-order.search.pro.ordinamentoCrescente": string;
    "view-order.search.pro.filtro": string;
    "view-order.search.pro.committente": string;
    "view-order.search.pro.committente.order": string;
    "view-order.search.pro.operatore": string;
    "view-order.search.pro.operatore.order": string;
    "view-order.search.pro.year": string;
    "view-order.search.pro.type": string;
    "view-order.search.pro.type.order": string;
    "view-order.search.pro.city": string;
    "view-order.search.pro.tags": string;
    "view-order.search.pro.tags.order": string;
    "view-order.search.pro.task.status": string;
    "view-order.search.pro.team": string;
    "view-order.search.pro.team.order": string;
    "view-order.list.map": string;
    "view-order.go.order": string;
    "view-order.list.switch": string;
    "view-order.map.switch": string;
    "view-order.map.country": string;
    "view-order.map.country.not.found": string;
    "view-order.map.date.error.start": string;
    "view-order.map.date.error.end": string;
    "view-order.expired.list": string;
    "view-order.my-expired.list": string;
    "view-order.all-expired.list": string;
    "view-order.label.no-works": string;
    "view-order.work-list.label.activities": string;
    "view-order.work-list.label.internal-documents": string;
    "view-order.work-list.label.inspections": string;
    "view-order.work-list.label.annotations": string;
    "view-order.work-list.label.protocols": string;
    "view-order.work.label.work-name": string;
    "view-order.work.label.start-date": string;
    "view-order.work.label.expiration-date": string;
    "view-order.work.label.tooltip-start": string;
    "view-order.work.label.tooltip": string;
    "view-order.work.label.end-date": string;
    "view-order.work.label.reminder": string;
    "view-order.work.toast.created": string;
    "view-order.work.dashboard.activities": string;
    "view-order.work.dashboard.documents": string;
    "view-order.work.dashboard.protocols": string;
    "view-order.work.dashboard.inspections": string;
    "view-order.work.dashboard.annotations": string;
    "view-order.concluded": string;
    "view-order.work.card.concluded": string;
    "view-order.work.concluded": string;
    "view-order.work.municipal.practices.title": string;
    "view-order.work.municipal.practices.body": string;
    "view-order.work.add.task": string;
    "view-order.work.modal.title.create": string;
    "view-order.work.modal.work-delete": string;
    "view-order.work.summary.card.inspection-title": string;
    "view-order.work.summary.card.annotation-title": string;
    "view-order.work.summary.card.document-title": string;
    "view-order.work.summary.header-bar.document": string;
    "view-order.work.summary.header-bar.origin": string;
    "view-order.work.summary.header-bar.upload-date": string;
    "view-order.work.summary.header-bar.document-type": string;
    "view-order.work.summary.header-bar.work": string;
    "view-order.work.summary.card.empty-inspection": string;
    "view-order.work.summary.card.empty-annotation": string;
    "view-order.import.title": string;
    "view-order.import.description": string;
    "view-order.import.button": string;
    "view-order.import.delete": string;
    "view-order.loading.list": string;
    "view-order.costo.utente": string;
    "view-order.diritti.utente": string;
}

interface ViewOrder {
    it: Translations;
    en: Translations;
}

export const translationsViewOrder: ViewOrder = {
    it: {
        "view-order.list": "Elenco commesse",
        "view-order.search.pro": "Ricerca avanzata",
        "view-order.search.pro.section1": "Generale",
        "view-order.search.pro.section2": "Operativo",
        "view-order.search.pro.section3": "Economico",
        "view-order.search.pro.data.inizio": "Dall'anno",
        "view-order.search.pro.data.fine": "All'anno",
        "view-order.search.pro.vista": "Vista",
        "view-order.search.pro.ordinamento": "Ordinamento",
        "view-order.search.pro.ordinamentoCrescente": "Ordinamento crecente",
        "view-order.search.pro.filtro": "Filtro",
        "view-order.search.pro.committente": "Committente",
        "view-order.search.pro.committente.order": "Committente commessa",
        "view-order.search.pro.operatore": "Operatore",
        "view-order.search.pro.operatore.order": "Operatore commessa",
        "view-order.search.pro.year": "Anno",
        "view-order.search.pro.type": "Tipo",
        "view-order.search.pro.type.order": "Tipo commessa",
        "view-order.search.pro.city": "Località",
        "view-order.search.pro.tags": "Tag",
        "view-order.search.pro.tags.order": "Tag commessa",
        "view-order.search.pro.task.status": "Stato avanzamento",
        "view-order.search.pro.team": "Team",
        "view-order.search.pro.team.order": "Team commessa",
        "view-order.list.map": "Mappa commesse",
        "view-order.go.order": "Vai alla commessa",
        "view-order.list.switch": "Lista",
        "view-order.map.switch": "Mappa",
        "view-order.map.country": "Comuni",
        "view-order.map.country.not.found": "Comune non presente",
        "view-order.map.date.error.start":
            "la data di inizio periodo non può essere maggiore rispetto alla data finale",
        "view-order.map.date.error.end":
            "la data di fine periodo non può essere minore rispetto alla data iniziale",
        "view-order.expired.list": "Attività",
        "view-order.my-expired.list": "Nessuna attività presente",
        "view-order.all-expired.list": "Nessuna attività in scadenza",
        "view-order.label.no-works": "Nessuna fase presente per questa commessa",
        "view-order.work-list.label.activities": "Attività",
        "view-order.work-list.label.internal-documents": "Documenti interni",
        "view-order.work-list.label.inspections": "Sopralluoghi",
        "view-order.work-list.label.annotations": "Annotazioni",
        "view-order.work.label.work-name": "Nome fase",
        "view-order.work.label.start-date": "Inizio fase",
        "view-order.work.label.expiration-date": "Fine prevista",
        "view-order.work.label.tooltip-start":
            'Come impostazione predefinita, la "data inizio fase" è la data di fine della fase precedente (se presente)',
        "view-order.work.label.tooltip": `La data di "possibile fine fase" non può superare da data di scadenza o fine commessa`,
        "view-order.work.label.end-date": "Data fine fase",
        "view-order.work.label.reminder": "Giorni preavviso scadenza",
        "view-order.work.toast.created": "Fase creata con successo",
        "view-order.work.dashboard.activities": "Attività",
        "view-order.work.dashboard.documents": "Documenti",
        "view-order.work.dashboard.protocols": "Protocolli",
        "view-order.work.dashboard.inspections": "Sopralluoghi",
        "view-order.work.dashboard.annotations": "Annotazioni",
        "view-order.work-list.label.protocols": "Protocolli",
        "view-order.concluded": "Commessa conclusa",
        "view-order.work.card.concluded": "Conclusa",
        "view-order.work.concluded": "Termina fase",
        "view-order.work.municipal.practices.title": "Imposta come Pratica Edilizia",
        "view-order.work.municipal.practices.body": `Attivando questa impostazione, nella fase verrà creata un'ulteriore sezione nominata "Pratica Comunale" all'interno della quale sarà possibile registrare gli estremi della pratica edilizia (Tipo di procedimento, descrizione lavori, data presentazione, ecc...) e nella sezione documenti i suoi allegati.`,
        "view-order.work.add.task": "Importa attività",
        "view-order.work.modal.title.create": "Crea fase",
        "view-order.work.modal.work-delete": "Elimina Fase",
        "view-order.work.summary.card.inspection-title": "Ultimi sopralluoghi",
        "view-order.work.summary.card.annotation-title": "Annotazioni recenti",
        "view-order.work.summary.card.document-title": "Documenti recenti",
        "view-order.work.summary.header-bar.document": "Documento",
        "view-order.work.summary.header-bar.origin": "Origine",
        "view-order.work.summary.header-bar.upload-date": "Data upload",
        "view-order.work.summary.header-bar.document-type": "Tipo documento",
        "view-order.work.summary.header-bar.work": "Fasi",
        "view-order.work.summary.card.empty-inspection": "Nessun sopralluogo per questa commessa",
        "view-order.work.summary.card.empty-annotation": "Nessun annotazione per questa commessa",
        "view-order.import.title": "Importazione istanza digitale",
        "view-order.import.description": `<p class="rounded-md text-black text-left">Clicca sul pulsante <span class="">“Importa pratica”</span> per caricare il file .zip della pratica che hai presentato digitalmente tramite i portali comunali. <br> In automatico verrà creata una nuova commessa contenente tutti gli allegati della pratica.</p> <p class="bg-secondaryDarkLight font-medium p-8 rounded-md text-black text-left relative block">Per le pratiche presentate tramite i portali comunali CPortal o gli Sportelli Telematici, oltre ai documenti, verranno riportati automaticamente nella commessa tutti i dati della pratica.</p>`,
        "view-order.import.button": "Importa pratica",
        "view-order.import.delete": "Annulla",
        "view-order.loading.list": "Caricamento elenco...",
        "view-order.costo.utente": "Costo orario €",
        "view-order.diritti.utente": "Diritti sulla commessa",
    },
    en: {
        "view-order.list": "Elenco commesse",
        "view-order.search.pro": "Ricerca avanzata",
        "view-order.search.pro.section1": "Generale",
        "view-order.search.pro.section2": "Operativo",
        "view-order.search.pro.section3": "Economico",
        "view-order.search.pro.data.inizio": "Dall'anno",
        "view-order.search.pro.data.fine": "All'anno",
        "view-order.search.pro.vista": "Vista",
        "view-order.search.pro.ordinamento": "Ordinamento",
        "view-order.search.pro.ordinamentoCrescente": "Ordinamento crecente",
        "view-order.search.pro.filtro": "Filtro",
        "view-order.search.pro.committente": "Committente",
        "view-order.search.pro.committente.order": "Committente commessa",
        "view-order.search.pro.operatore": "Operatore",
        "view-order.search.pro.operatore.order": "Operatore commessa",
        "view-order.search.pro.year": "Anno",
        "view-order.search.pro.type": "Tipo",
        "view-order.search.pro.type.order": "Tipo commessa",
        "view-order.search.pro.city": "Località",
        "view-order.search.pro.tags": "Tag",
        "view-order.search.pro.tags.order": "Tag commessa",
        "view-order.search.pro.task.status": "Stato avanzamento",
        "view-order.search.pro.team": "Team",
        "view-order.search.pro.team.order": "Team commessa",
        "view-order.list.map": "Mappa commesse",
        "view-order.go.order": "Vai alla commessa",
        "view-order.list.switch": "Lista",
        "view-order.map.switch": "Mappa",
        "view-order.map.country": "Comuni",
        "view-order.map.country.not.found": "Comune non presente",
        "view-order.map.date.error.start":
            "la data di inizio periodo non può essere maggiore rispetto alla data finale",
        "view-order.map.date.error.end":
            "la data di fine periodo non può essere minore rispetto alla data iniziale",
        "view-order.expired.list": "Attività",
        "view-order.my-expired.list": "Nessuna attività presente",
        "view-order.all-expired.list": "Nessuna attività in scadenza",
        "view-order.label.no-works": "No works in this order",
        "view-order.work-list.label.activities": "Activities",
        "view-order.work-list.label.internal-documents": "Internal documents",
        "view-order.work-list.label.inspections": "Inspections",
        "view-order.work-list.label.annotations": "Annotations",
        "view-order.work.label.work-name": "Work name",
        "view-order.work.label.start-date": "Inizio fase",
        "view-order.work.label.expiration-date": "Work expiration date",
        "view-order.work.label.tooltip-start":
            'Come impostazione predefinita, la "data inizio fase" è la data di fine della fase precedente (se presente)',
        "view-order.work.label.tooltip": `La data di "possibile fine fase" non può superare quella di fine commessa`,
        "view-order.work.label.end-date": "Data fine fase",
        "view-order.work.label.reminder": "Expiration reminder",
        "view-order.work.toast.created": "Work created successfully",
        "view-order.work.dashboard.activities": "Activities",
        "view-order.work.dashboard.documents": "Documents",
        "view-order.work.dashboard.protocols": "Protocols",
        "view-order.work.dashboard.inspections": "Inspections",
        "view-order.work.dashboard.annotations": "Annotations",
        "view-order.work-list.label.protocols": "Protocols",
        "view-order.concluded": "Order concluded",
        "view-order.work.card.concluded": "Concluded",
        "view-order.work.concluded": "Work concluded",
        "view-order.work.municipal.practices.title": "Imposta come Pratica Edilizia",
        "view-order.work.municipal.practices.body": `Attivando questa impostazione, nella fase verrà creata un'ulteriore sezione nominata "Pratica Comunale" all'interno della quale sarà possibile registrare glie estremi della pratica edilizia (Tipo di procedimento, descrizione lavori, data presentazione, ecc...) e nella sezione documenti i suoi allegati.`,
        "view-order.work.add.task": "Importa attività",
        "view-order.work.modal.title.create": "Create Work",
        "view-order.work.modal.work-delete": "Delete work",
        "view-order.work.summary.card.inspection-title": "Last inspections",
        "view-order.work.summary.card.annotation-title": "Last annotations",
        "view-order.work.summary.card.document-title": "Documenti recenti",
        "view-order.work.summary.header-bar.document": "Document",
        "view-order.work.summary.header-bar.origin": "Origin",
        "view-order.work.summary.header-bar.upload-date": "Date upload",
        "view-order.work.summary.header-bar.document-type": "Document Type",
        "view-order.work.summary.header-bar.work": "Work",
        "view-order.work.summary.card.empty-inspection": "No inspections aviable for this order",
        "view-order.work.summary.card.empty-annotation": "No annotations aviable for this order",
        "view-order.import.title": "Importazione istanza digitale",
        "view-order.import.description": `<p class="rounded-md text-black text-left">Clicca sul pulsante <span class="">“Importa pratica”</span> per caricare il file .zip della pratica che hai presentato digitalmente tramite i portali comunali. <br> In automatico verrà creata una nuova commessa contenente tutti gli allegati della pratica.</p> <p class="bg-secondaryDarkLight font-medium p-2 rounded-md text-black text-left">Per le pratiche presentate tramite i portali comunali CPortal o gli Sportelli Telematici, oltre ai documenti, verranno riportati automaticamente nella commessa tutti i dati della pratica.</p>`,
        "view-order.import.button": "importa pratica",
        "view-order.import.delete": "annulla",
        "view-order.loading.list": "Caricamento elenco...",
        "view-order.costo.utente": "Costo orario €",
        "view-order.diritti.utente": "Diritti sulla commessa",
    },
};

export type TTranslationsViewOrder = keyof Translations;
