// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faCog,
    faEuroSign,
    faFolderOpen,
    faLightbulbOn,
    faMapMarker,
    faThList,
    faUserHardHat,
    faUsers,
} from "@fortawesome/pro-light-svg-icons";
import React, { FC, MutableRefObject, memo } from "react";
import { useSelector } from "react-redux";

import { StatoDiritto } from "@magoot-sdk/api/diritti/response";
import { useToolbarNavigation } from "@magoot-sdk/hooks/useToolbarNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { useDiritti } from "@magoot-sdk/redux-modules/hooks/diritti/useDiritti";
import { useOrderStats } from "@magoot-sdk/redux-modules/hooks/stats/useOrderStats";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { selectorIsPartnerOutside } from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import { handleDrawerNavigation } from "@magoot-sdk/utils/components/drawer";

import { UIIcon } from "@components-ui/Icon";

export interface Props {
    idStudio: number;
    idOrder: number;
    idCustomer?: number;
    idIdea?: number;
    locationPathname: string;
    goWork?: () => void;
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const OrderFields: FC<Props> = memo(
    ({
        idStudio,
        idOrder,
        idCustomer,
        idIdea,
        locationPathname,
        goWork,
        drawerRef,
    }): JSX.Element => {
        const {
            onNavigateToCommimentList,
            onNavigateToOperatorList,
            onNavigateToIdeaList,
            onNavigateToWorkList,
            onNavigateToSettings,
            onNavigateToTerritorialData,
            onNavigateToSummary,
            onNavigateToPayments,
            isSummary,
            isCommitment,
            isOperator,
            isTerritorialData,
            isWork,
            isIdea,
            isPayment,
            isSetting,
            isNewRoutes,
        } = useToolbarNavigation({ idStudio, idOrder, idCustomer, idIdea, locationPathname });
        const {
            dirittoCommittente,
            dirittoOperatore,
            dirittoDatoTerritoriale,
            dirittoBachecaIdee,
        } = useDiritti();
        const locale = useLocale();
        const orderStats = useOrderStats(idOrder);
        const isPartnerOutside = useSelector(selectorIsPartnerOutside);

        const handleGoWork = () => {
            onNavigateToWorkList();

            if (goWork) goWork();
        };

        return (
            <ul className='shadow-2xk menu h-full w-3/4 bg-secondaryVeryDark p-0 md:w-2/4 xl:w-[6rem] relative flex-nowrap overflow-y-auto'>
                <li
                    onClick={() =>
                        handleDrawerNavigation({ navigateFn: onNavigateToSummary, drawerRef })
                    }
                    className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-8 ${
                        isSummary ? "bg-sidebarDashboard" : ""
                    }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                >
                    <UIIcon svgIcon={faThList as IconProp} color='white' size='lg' />
                    <p className='p-0 text-xs text-white active:bg-transparent'>
                        <span className='relative'>
                            {translate({ locale, id: "general.label.summary" })}
                        </span>
                    </p>
                </li>

                {dirittoCommittente !== StatoDiritto.Nessuno && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: onNavigateToCommimentList,
                                drawerRef,
                            })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark relative flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-8 ${
                            isCommitment ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faUsers as IconProp} color='white' size='lg' />

                        <div className='relative block w-auto xl:w-full p-0 text-center text-xs text-white active:bg-transparent'>
                            <span className='border border-secondary bg-secondary w-[1rem] absolute -right-32 xl:right-0 xl:-top-32'>
                                {orderStats.committenti}
                            </span>
                            <span className='relative'>
                                {translate({ locale, id: "general.label.commitments" })}
                            </span>
                        </div>
                    </li>
                )}

                {dirittoOperatore !== StatoDiritto.Nessuno && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: onNavigateToOperatorList,
                                drawerRef,
                            })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4 ${
                            isOperator ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faUserHardHat as IconProp} color='white' size='lg' />

                        <div className='relative block w-auto xl:w-full p-0 text-center text-xs text-white active:bg-transparent'>
                            <span className='border border-secondary bg-secondary w-[1rem] absolute -right-32 xl:right-0 xl:-top-32'>
                                {orderStats.operatori}
                            </span>
                            <span className='relative'>
                                {translate({ locale, id: "general.label.operators" })}
                            </span>
                        </div>
                    </li>
                )}

                {dirittoDatoTerritoriale !== StatoDiritto.Nessuno && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: onNavigateToTerritorialData,
                                drawerRef,
                            })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4 ${
                            isTerritorialData ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faMapMarker as IconProp} color='white' size='lg' />
                        <p className='p-0 text-xs text-white active:bg-transparent text-center'>
                            <span className='relative'>
                                {translate({ locale, id: "general.label.territorial-data" })}
                            </span>
                        </p>
                    </li>
                )}

                <li
                    onClick={() => handleDrawerNavigation({ navigateFn: handleGoWork })}
                    className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4  ${
                        isWork ? "bg-sidebarDashboard" : ""
                    }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                >
                    <UIIcon svgIcon={faFolderOpen as IconProp} color='white' size='lg' />

                    <div className='relative block w-auto xl:w-full p-0 text-center text-xs text-white active:bg-transparent'>
                        <span className='border border-secondary bg-secondary w-[1rem] absolute -right-32 xl:right-0 xl:-top-32'>
                            {orderStats.lavorazioni}
                        </span>
                        <span className='relative active:bg-transparent'>
                            {translate({ locale, id: "general.label.phases" })}
                        </span>
                    </div>
                </li>

                {dirittoBachecaIdee !== StatoDiritto.Nessuno && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({ navigateFn: onNavigateToIdeaList, drawerRef })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4 ${
                            isIdea ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faLightbulbOn as IconProp} color='white' size='lg' />

                        <div className='relative hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark block w-fit p-0 text-center text-xs text-white active:bg-transparent'>
                            <span className='bg-secondary w-[1rem] border border-secondary absolute -right-32 xl:right-0 xl:-top-32'>
                                {orderStats.idee}
                            </span>
                            <span className='relative active:bg-transparent'>
                                {translate({ locale, id: "general.label.ideas" })}
                            </span>
                        </div>
                    </li>
                )}

                {!isPartnerOutside && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: onNavigateToPayments,
                                drawerRef,
                            })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4 ${
                            isPayment ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faEuroSign as IconProp} color='white' size='lg' />
                        <p className='p-0 text-xs text-white active:bg-transparent'>
                            <span className='relative'>
                                {translate({ locale, id: "general.label.payments" })}
                            </span>
                        </p>
                    </li>
                )}

                {!isPartnerOutside && (
                    <li
                        onClick={() =>
                            handleDrawerNavigation({
                                navigateFn: onNavigateToSettings,
                                drawerRef,
                            })
                        }
                        className={`group hover:bg-gradient-to-l hover:from-sidebarDashboard hover:to-secondaryVeryDark flex cursor-pointer flex-row items-center space-x-8 rounded-none border-none px-12 py-16 xl:flex-col xl:space-x-0 xl:space-y-4 ${
                            isSetting ? "bg-sidebarDashboard" : ""
                        }
            ${isNewRoutes ? "opacity-75 pointer-events-none" : ""}
            `}
                    >
                        <UIIcon svgIcon={faCog as IconProp} color='white' size='lg' />
                        <p className='p-0 text-xs text-white active:bg-transparent'>
                            <span className='relative'>
                                {translate({ locale, id: "general.label.settings" })}
                            </span>
                        </p>
                    </li>
                )}
            </ul>
        );
    }
);

OrderFields.displayName = "OrderFields";
