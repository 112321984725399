import { TResponse } from "../../declarations/general/responseGeneral";

type Utente = {
    id: number;
    cognome: string;
    nome: string;
    srcImage: string;
};

enum StatoDiritto {
    Nessuno = 0,
    Lettura = 1,
    LetturaEScrittura = 2,
    Scrittura = 3,
}

enum StatoSezione {
    Commessa = 1,
    Lavorazione = 2,
    Attivita = 3,
    Documento = 4,
    Sopralluogo = 5,
    Annotazione = 6,
    Committente = 7,
    Operatore = 8,
    DatoTerritoriale = 9,
    BachecaIdee = 10,
    Preventivo = 11,
    Incasso = 12,
    Spese = 13,
    CostiCommessa = 14,
    TeamCommessa = 15,
}

type Diritto = {
    diritto: {
        id: StatoDiritto;
        descr: string;
    };
    sezione: {
        id: StatoSezione;
        descr: string;
    };
};

type UtentiEDiritti = {
    dirittiSezione: Diritto[];
    idCommessa: number | null;
    utente: Utente;
};

export type TResponseTabellaDiritti = TResponse<{ gruppoUtentiDiritti: UtentiEDiritti[] }>;
export type { Utente, Diritto, UtentiEDiritti };
export { StatoDiritto, StatoSezione };
