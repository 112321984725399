// #region ::: IMPORT
import { WorkCreateTask } from "@components-view/dashboard/ViewWorkDetail/components/FormWorkDetail/partials/WorkCreateTask";
import { WorkDelete } from "@components-view/dashboard/ViewWorkDetail/components/FormWorkDetail/partials/WorkDelete";
import { WorkMunicipalPractices } from "@components-view/dashboard/ViewWorkDetail/components/FormWorkDetail/partials/WorkMunicipalPractices";
import { useFormik } from "formik";
import React, { ChangeEvent, FC, memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { OrderStatus } from "@magoot-sdk/constants/general";
import { translate } from "@magoot-sdk/i18n/translate";
import { useOrderDetail } from "@magoot-sdk/redux-modules/hooks/hooksOrder";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useWorkID } from "@magoot-sdk/redux-modules/hooks/useWorkId";
import { useWorkDetail } from "@magoot-sdk/redux-modules/hooks/work/useWorkDetail";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { TDictionaryTask } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";
import { TWork } from "@magoot-sdk/redux-modules/reducers/reducerWorks/declarations";
import { selectorWorkList } from "@magoot-sdk/redux-modules/selectors/selectorWork";

import { UIDatePicker } from "@components-ui/DatePicker";
import { InputGeneric } from "@components-ui/Input/Generic";
import { InputWorkSearch } from "@components-ui/Input/WorkSearch";
import { useInputWorks } from "@components-ui/Input/WorkSearch/useInputWorks";
import { UIModal } from "@components-ui/Modal";

import { WorksSummary } from "./WorksSummary";

// #endregion

interface Props {
    onCloseModal: () => void;
    idWork?: number;
    modalTitle: string;
}

export const FormWork: FC<Props> = memo(({ onCloseModal, idWork, modalTitle }): JSX.Element => {
    const locale = useLocale();
    const { idOrder } = useOrderID();
    const { idWork: currentWork } = useWorkID();
    const currentWorks = useSelector((store: TStore) => selectorWorkList({ store, idOrder }));
    const getPreviousWorkDate = useMemo(() => {
        const lastWork = currentWorks?.at(-1);
        if (lastWork && lastWork?.dataFine) return new Date(lastWork?.dataFine);
        // if (lastWork && lastWork?.dataScadenza) return new Date(lastWork?.dataScadenza);
        return undefined;
    }, [currentWorks, currentWork]);

    const { workDetail, onCreate } = useWorkDetail({
        idWork,
        idOrder,
    });
    const { order } = useOrderDetail();

    const dataInizio = useMemo(() => {
        if (!workDetail && getPreviousWorkDate) return getPreviousWorkDate;
        if (workDetail && workDetail.dataInizio) return new Date(workDetail.dataInizio);
        return null;
    }, [getPreviousWorkDate, workDetail]);

    const [dictionary, setDictionary] = useState<TDictionaryTask | null>(null);

    const initialValues: TWork = {
        terminate:
            workDetail && workDetail?.statoEsecuzione
                ? workDetail?.statoEsecuzione?.id === OrderStatus.Completata
                : false,
        descr: workDetail && workDetail?.descr ? workDetail?.descr : "",
        dataInizio,
        dataScadenza:
            workDetail && workDetail.dataScadenza ? new Date(workDetail.dataScadenza) : null,
        giorniAvvisoScadenza:
            workDetail && workDetail.giorniAvvisoScadenza ? workDetail.giorniAvvisoScadenza : null,
        praticaComunale:
            workDetail && workDetail.praticaComunale ? workDetail.praticaComunale : false,
        idDizionario: dictionary ? dictionary.id : null,
        aggiungiAttivita: (dictionary && dictionary?.attivita.length > 0) ?? false,
    };

    const validationSchema = Yup.object().shape({
        descr: Yup.string().required(translate({ id: "general.label.required", locale })),
        giorniAvvisoScadenza: Yup.number()
            .typeError(translate({ id: "general.label.required", locale }))
            .min(0, translate({ id: "general.label.not-negative", locale }))
            .nullable(),
        praticaComunale: Yup.boolean(),
        aggiungiAttivita: Yup.boolean(),
    });

    const {
        values,
        touched,
        errors,
        submitForm,
        setFieldValue,
        isValid,
        handleChange,
        handleBlur,
    } = useFormik<TWork>({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        onSubmit: (valuesSubmit) => {
            onCreate({
                descr: valuesSubmit.descr,
                dataInizio: valuesSubmit.dataInizio,
                dataScadenza: valuesSubmit.dataScadenza,
                giorniAvvisoScadenza: valuesSubmit.giorniAvvisoScadenza,
                praticaComunale: valuesSubmit.praticaComunale ?? false,
                idDizionario: dictionary ? dictionary.id : null,
                aggiungiAttivita: values.aggiungiAttivita ?? false,
            });
            onCloseModal();
        },
    });

    const { debounceOnChange, works } = useInputWorks({
        initialValue: values.descr ?? "",
    });

    const onSelectWork = (value: TDictionaryTask): void => {
        setDictionary(value);
        setFieldValue("descr", value?.descr ?? value?.descrEstesa ?? "");
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event);
        debounceOnChange(event);
    };

    const onChangeDataInizio = (value: Date | null) => {
        setFieldValue("dataInizio", value);
        if (!value) {
            setFieldValue("dataInizio", "");
        }
    };

    const onChangeDataScadenza = (value: Date | null) => {
        setFieldValue("dataScadenza", value);
        if (!value) {
            setFieldValue("giorniAvvisoScadenza", "");
        }
    };

    useEffect(() => {
        if (dictionary) setFieldValue("aggiungiAttivita", dictionary?.attivita.length > 0);
    }, [dictionary]);

    return (
        <UIModal
            title={modalTitle}
            onClose={onCloseModal}
            onSave={submitForm}
            isDisabledSave={!isValid || !values.descr}
            size='7xl'
        >
            <section className='flex flex-col lg:flex-row gap-40'>
                <form className='flex flex-col space-y-20 lg:w-3/6'>
                    <div className='border p-12 bg-gray-50 rounded mb-12'>
                        <h2 className='text-left font-medium uppercase text-secondaryDark'>
                            Dati Fase
                        </h2>
                        <InputWorkSearch
                            workName={values.descr ?? ""}
                            error={touched.descr ? errors.descr : ""}
                            options={works}
                            onAutoComplete={onSelectWork}
                            onChange={onSearch}
                            onBlur={handleBlur}
                        />
                        <div className='flex flex-col lg:flex-row sm:items-end gap-8'>
                            <UIDatePicker
                                value={values.dataInizio ?? null}
                                label={translate({
                                    locale,
                                    id: "view-order.work.label.start-date",
                                })}
                                onChange={onChangeDataInizio}
                                placement='bottom-end'
                                fieldName='dataInizio'
                                minDate={
                                    order && order?.dataInizio
                                        ? new Date(order.dataInizio)
                                        : undefined
                                }
                                maxDate={
                                    (order && (order?.dataScadenza || order?.dataFine)) ||
                                    values?.dataScadenza
                                        ? new Date(
                                              values.dataScadenza ??
                                                  order.dataFine ??
                                                  order.dataScadenza ??
                                                  ""
                                          )
                                        : undefined
                                }
                                tooltipText={translate({
                                    locale,
                                    id: "view-order.work.label.tooltip-start",
                                })}
                            />
                            <UIDatePicker
                                value={values.dataScadenza ?? null}
                                label={translate({
                                    locale,
                                    id: "view-order.work.label.expiration-date",
                                })}
                                onChange={onChangeDataScadenza}
                                placement='bottom-end'
                                fieldName='dataScadenza'
                                minDate={
                                    order && order?.dataInizio
                                        ? new Date(order.dataInizio)
                                        : undefined
                                }
                                maxDate={
                                    order && (order?.dataScadenza || order?.dataFine)
                                        ? new Date(order?.dataFine ?? order?.dataScadenza ?? "")
                                        : undefined
                                }
                                tooltipText={translate({
                                    locale,
                                    id: "view-order.work.label.tooltip",
                                })}
                            />
                            <div className='w-full lg:w-[33rem]'>
                                <InputGeneric
                                    dataCy='TO_DO'
                                    label='view-order.work.label.reminder'
                                    type='number'
                                    min={0}
                                    fieldName='giorniAvvisoScadenza'
                                    disabled={values.dataScadenza === null}
                                    value={values.giorniAvvisoScadenza ?? undefined}
                                    info={
                                        touched.giorniAvvisoScadenza
                                            ? errors.giorniAvvisoScadenza
                                            : ""
                                    }
                                    valid={
                                        !(
                                            touched.giorniAvvisoScadenza &&
                                            errors.giorniAvvisoScadenza
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='border flex bg-gray-50 flex-col gap-12 p-12 rounded'>
                        <h2 className='text-left font-medium uppercase text-secondaryDark'>
                            ALTRI DATI
                        </h2>
                        <WorkMunicipalPractices
                            isChecked={values.praticaComunale ?? false}
                            setIsChecked={(value) => setFieldValue("praticaComunale", value)}
                        />

                        <WorkCreateTask
                            isChecked={values.aggiungiAttivita ?? false}
                            setIsChecked={(value) => setFieldValue("aggiungiAttivita", value)}
                        />
                        {idWork && <WorkDelete idWork={idWork} onCloseModal={onCloseModal} />}
                    </div>
                </form>
                <div className='lg:w-3/6 border-l p-24 bg-gray-50'>
                    <WorksSummary />
                </div>
            </section>
        </UIModal>
    );
});
FormWork.displayName = "FormWork";
