import { TParamsTabellaDiritti } from "@magoot-sdk/api/diritti/params";
import { UtentiEDiritti } from "@magoot-sdk/api/diritti/response";
import { Actions, TError } from "@magoot-sdk/redux-modules/utils/Actions";

import {
    TActionUtentiDirittiCommessaFailure,
    TActionUtentiDirittiCommessaRequest,
    TActionUtentiDirittiCommessaSuccess,
    TActionUtentiDirittiFailure,
    TActionUtentiDirittiRequest,
    TActionUtentiDirittiSuccess,
} from "./declarations";

export const actionUtentiDirittiRequest = (
    payload: TParamsTabellaDiritti
): TActionUtentiDirittiRequest => ({
    type: Actions.utentiDirittiRequest,
    payload,
});

export const actionUtentiDirittiSuccess = (payload: {
    gruppoUtentiDiritti: UtentiEDiritti[];
}): TActionUtentiDirittiSuccess => ({
    type: Actions.utentiDirittiSuccess,
    payload,
});

export const actionUtentiDirittiFailure = (payload: TError): TActionUtentiDirittiFailure => ({
    type: Actions.utentiDirittiFailure,
    payload,
});

export const actionUtentiDirittiCommessaRequest = (
    payload: TParamsTabellaDiritti
): TActionUtentiDirittiCommessaRequest => ({
    type: Actions.utentiDirittiCommessaRequest,
    payload,
});

export const actionUtentiDirittiCommessaSuccess = (payload: {
    gruppoUtentiDiritti: UtentiEDiritti[];
}): TActionUtentiDirittiCommessaSuccess => ({
    type: Actions.utentiDirittiCommessaSuccess,
    payload,
});

export const actionUtentiDirittiCommessaFailure = (
    payload: TError
): TActionUtentiDirittiCommessaFailure => ({
    type: Actions.utentiDirittiCommessaFailure,
    payload,
});
