import { Actions } from "../../utils/Actions";
import { TReducerUtentiDiritti, TReducerUtentiDirittiActions } from "./declarations";

export const reducerUtentiDiritti = (
    prevStore: TReducerUtentiDiritti = { value: undefined, loading: false },
    action: TReducerUtentiDirittiActions
): TReducerUtentiDiritti => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.utentiDirittiSuccess:
        case Actions.utentiDirittiCommessaSuccess:
            newStore.value = action.payload.gruppoUtentiDiritti;
            return newStore;
        default:
            return prevStore;
    }
};
