import { useSelector } from "react-redux";

import { StatoDiritto, StatoSezione } from "@magoot-sdk/api/diritti/response";
import { DirittiHook } from "@magoot-sdk/redux-modules/reducers/reducerUtentiDiritti/declarations";
import { selectorUtenteDiritti } from "@magoot-sdk/redux-modules/selectors/selectorUtentiDiritti";
import { selectorUserID } from "@magoot-sdk/redux-modules/selectors/selectorsAuth";

import { TStore } from "../../reducers/declarations";

export const useDiritti = (): DirittiHook => {
    const userID = useSelector(selectorUserID);

    const diritti = useSelector((store: TStore) =>
        selectorUtenteDiritti({ store, idUtente: userID })
    );

    const dirittoCommessa = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Commessa
    )?.diritto.id;

    const dirittoLavorazione = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Lavorazione
    )?.diritto.id;

    const dirittoAttivita = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Attivita
    )?.diritto.id;

    const dirittoDocumento = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Documento
    )?.diritto.id;

    const dirittoSopralluogo = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Sopralluogo
    )?.diritto.id;

    const dirittoAnnotazione = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Annotazione
    )?.diritto.id;

    const dirittoCommittente = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Committente
    )?.diritto.id;

    const dirittoOperatore = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Operatore
    )?.diritto.id;

    const dirittoDatoTerritoriale = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.DatoTerritoriale
    )?.diritto.id;

    const dirittoBachecaIdee = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.BachecaIdee
    )?.diritto.id;

    const dirittoPreventivo = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Preventivo
    )?.diritto.id;

    const dirittoIncasso = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.Incasso
    )?.diritto.id;

    const dirittoSpese = diritti?.dirittiSezione.find((d) => d.sezione.id === StatoSezione.Spese)
        ?.diritto.id;

    const dirittoCostiCommessa = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.CostiCommessa
    )?.diritto.id;

    const dirittoTeamCommessa = diritti?.dirittiSezione.find(
        (d) => d.sezione.id === StatoSezione.TeamCommessa
    )?.diritto.id;

    const tabAttiveFasi = diritti?.dirittiSezione.filter(
        (d) => d.sezione.id >= 3 && d.sezione.id <= 6 && d.diritto.id !== StatoDiritto.Nessuno
    );

    const tabPagamenti = diritti?.dirittiSezione.filter(
        (d) => d.sezione.id >= 11 && d.sezione.id <= 13 && d.diritto.id !== StatoDiritto.Nessuno
    );

    return {
        dirittoCommessa,
        dirittoLavorazione,
        dirittoAttivita,
        dirittoDocumento,
        dirittoSopralluogo,
        dirittoAnnotazione,
        dirittoCommittente,
        dirittoOperatore,
        dirittoDatoTerritoriale,
        dirittoBachecaIdee,
        dirittoPreventivo,
        dirittoIncasso,
        dirittoSpese,
        dirittoCostiCommessa,
        dirittoTeamCommessa,
        tabAttiveFasi,
        tabPagamenti,
    };
};
