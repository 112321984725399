import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
    ID_OWNER,
    ID_ROLE_ADMIN,
    ID_ROLE_COLLABORATOR_INDOOR,
} from "@magoot-sdk/constants/general";

import { actionUserListRequest } from "../../actions/user/actionUserList";
import { TStore } from "../../reducers/declarations";
import { TRole, TUser } from "../../reducers/reducerUsers/declarations";
import { getUsers } from "../../selectors/selectorsUser";
import { useDispatch } from "../../utils/useDispatch";

// #region ::: SELECTORS
const selectorUserListLoading = (store: TStore): boolean => store.ui?.isLoadingUsers || false;

export const selectorUserList = (store: TStore): TUser[] => {
    const users = getUsers(store);
    const userList = Object.values(users);
    return userList;
};

export const selectorTeamList = (store: TStore): TUser[] => {
    const userList = selectorUserList(store);
    const filteredList = userList.filter((user) => user.attivo);

    return filteredList;
};

export const selectorAllTeamList = (store: TStore): TUser[] => {
    const userList = selectorUserList(store);
    return userList;
};

export const mapRuolo = (ruolo?: TRole) => {
    if (ruolo === ID_OWNER) return "Titolare";
    if (ruolo === ID_ROLE_ADMIN) return "Amministratore";
    if (ruolo === ID_ROLE_COLLABORATOR_INDOOR) return "Collaboratore interno";

    return "";
};
// #endregion

export const useTeamList = ({
    reload = true,
    ruolo,
}: {
    reload?: boolean;
    ruolo?: TRole;
}): {
    userList: TUser[];
    teamList: TUser[];
    allTeamList: TUser[];
    getRuolo: string;
    isLoadingTeam: boolean;
} => {
    const dispatch = useDispatch();
    const idStudio = useSelector((store: TStore) => store.auth?.idStudio);
    const userList = useSelector(selectorUserList);
    const teamList = useSelector(selectorTeamList);
    const allTeamList = useSelector(selectorAllTeamList);
    const isLoadingTeam = useSelector(selectorUserListLoading);
    const getRuolo = mapRuolo(ruolo);

    useEffect(() => {
        if (!!idStudio && reload) dispatch(actionUserListRequest({ idStudio }));
    }, [dispatch, idStudio]);

    return { userList, allTeamList, teamList, isLoadingTeam, getRuolo };
};
